import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import Loader from "./components/Loader";
const App = React.lazy(() => import("./App"));

ReactDOM.render(
    <React.StrictMode>
        <Suspense
            fallback={
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Loader animate={false} />
                </div>
            }
        >
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
