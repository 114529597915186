export interface IEnumHelperObject {
    id: number;
    value: string;
    title?: string;
}
export function enumToArray(enumValues: any): string[] {
    const arr: string[] = [];
    for (const item in enumValues) {
        if (isNaN(Number(item))) {
            arr.push(item);
        }
    }
    return arr;
}
export function enumToValuesArray(enumValues: any): number[] {
    const arr: number[] = [];
    for (const item in enumValues) {
        if (!isNaN(Number(item))) {
            arr.push(parseInt(item));
        }
    }
    return arr;
}
export function enumToArrayObject(enums: any, transformCb: Function | null = null): IEnumHelperObject[] {
    const enumArr = enumToArray(enums);
    const enumValues = enumToValuesArray(enums);

    const result: IEnumHelperObject[] = [];
    for (let i = 0; i < enumArr.length; i++) {
        const row: IEnumHelperObject = {
            id: enumValues[i],
            value: enumArr[i],
        };
        if (!!transformCb) {
            row.title = transformCb(enumArr[i]);
        }
        result.push(row);
    }
    return result;
}

export function getEnumValue(enumValues: any, value: number): string | null {
    if (typeof enumValues[value] == "undefined") {
        return null;
    }
    return enumValues[value];
}

/**
 *  из массива значений получает битовую маску
 * @param values массив значений
 * @returns битовая маска
 */
export function getFlagByEnumValues(values: number[]): number {
    let result = 0;
    for (const item of values) {
        result |= item;
    }
    return result;
}

/**
 * проверяем входит ли mask2 в подмножество mask1
 * @param mask1 маска с которой сравниваем
 * @param mask2 что сравниваем
 * @returns
 */
export function checkFlagIncludes(mask1: number, mask2: number): boolean {
    return (mask1 & mask2) === mask2;
}
