const ruComponents = {
    accessFeatures: {
        noCategory: "Без категории",
        add: {
            title: "Новый доступ",
            helperText: "Если не указано, наследуется от родителя",
            error: "Ошибка при создании нового доступа!",
            success: 'Создан новый доступ "%s"',
        },
    },
    spreadSheet: {
        revertChanges: "Отменить",
        saveChanges: "Сохранить",
        addRow: "Добавить строку",
        deleteRow: "Удалить",
        copyRow: "Копировать",
    },
    camera: {
        camera: "Камера",
        title: "Сделайте фото",
        errors: {
            overconstrained: "Ваша камера не поддерживает минимального разрешения",
            permissionDenied: "Доступ запрещен. Пожалуйста, дайте разрешение на использование камеры для сайта",
            notFound: "Камера не обнаружена на устройстве",
            blobNotCreated: "Ошибка при создании изображения",
            recordingError: "Ошибка при попытке записать видео",
            lostMediaRecorder: "Потерян объект MediaRecorder",
            stopRecord: "Ошибка при остановке видеосъемки",
            recordedChunksLength: "Проблемы с записанным видео! Перезапишите видео",
            unknown: "Неизвестная ошибка",
        },
        errorMessages: {
            noCameraAccessible: "Нет доступа к камере. Подключите камеру или попробуйте в другом браузере.",
            permissionDenied: "Доступ запрещен. Пожалуйста, дайте разрешение на использование камеры для сайта.",
            switchCamera: "Невозможно переключить камеру на другую, потому что доступно только одна.",
            canvas: "Canvas не поддерживается.",
        },
    },
    comments: {
        tooltip: {
            upload: "Добавить файл, максимальный размер %s",
        },
        errors: {
            addComment: "Ошибка при попытке добавить комментарий",
        },
    },
    confirm: {
        title: "Вы уверены?",
        okText: "Да",
        noText: "Отмена",
        confirmDelete: {
            title: "Удаление",
            text: "Вы уверены, что хотите это удалить?",
            okText: "Да, удалить",
            noText: "Отмена",
        },
    },
    inputContacts: {
        noRecords: "Нет контактов",
        addTitle: "Добавить контакт",
        editTitle: "Редактировать контакт",
        fields: {
            contactPhone: "Номер телефона",
            contactEmail: "E-mail",
            contactTelegram: "Логин",
            contactType: "Тип контакта",
            description: "Описание",
            isMain: "Предпочтительный способ связи",
        },
        actions: {
            copy: "Скопировать",
            edit: "Редактировать",
            delete: "Удалить",
            copied: 'Текст "%s" скопирован в буфер обмена',
            whatsApp: "в WhatsApp",
            telegram: "в Telegram",
        },
        deleteConfirm: {
            title: "Удалить контакт",
            text: 'Вы уверены, что хотите удалить контакт "%s"',
        },
        errors: {
            required: "Поле обязательно к заполнению",
            email: "Укажите корректный Email",
            phone: "Укажите корректный номер телефона",
            telegram: "Укажите корректный логин в Telegram",
        },
    },
    crud: {
        loader: "Идет получение данных. Подождите...",
        deleteConfirm: {
            title: "Удаление записей",
            text: "Вы уверены что хотите удалить %s записей?",
            okText: "Удалить",
        },
        errors: {
            list: "Не удалось получить данные с сервера",
            delete: "Не удалось удалить выбранные записи",
            edit: "Не удалось получить запись с сервера",
            save: "Не удалось сохранить данные на сервере",
        },
    },
    crudAsync: {
        loader: "Идет получение данных. Подождите...",
        editTitle: "Редактирование записи",
        addTitle: "Новая запись",
        deleteConfirm: {
            title: "Удаление записей",
            text: "Вы уверены что хотите удалить %s записей?",
            okText: "Удалить",
        },
        success: {
            delete: "Удалено %s записей",
            save: "Данные отредактированы",
        },
        errors: {
            list: "Не удалось получить данные с сервера!",
            delete: "Не удалось удалить выбранные записи!",
            edit: "Не удалось получить запись с сервера!",
            save: "Не удалось сохранить данные на сервере!",
            initialValue: "Не указаны данные по умолчанию",
        },
    },
    dateRange: {
        invalidDate: "Некорректная дата",
    },
    errorPage: {
        error: "Ошибка №%s",
        error0: "Внутренняя ошибка",
        error404: "Страница не найдена",
        errorMessage: "Текст ошибки",
        errorName: "Тип ошибки",
        toHome: "На главную",
    },
    filesManager: {
        addFile: "Добавить файл, максимальный размер %s",
        addFiles: "Добавить файлы, максимальный размер %s. Не более %s файлов за раз",
        deleteConfirm: {
            title: "Удалить файл",
            text: 'Вы уверены, что хотите удалить файл "%s"?',
            okText: "Да, удалить",
        },
        errors: {
            delete: "Не удалось удалить файл %s",
        },
    },
    fileShareFolder: {
        noFilesOrFolders: "Данная папка пуста",
        folders: "Папки",
        files: "Файлы",
        getLink: "Поделиться ссылкой",
        showFile: "Просмотр",
        downloadFile: "Скачать",
        config: {
            sortName: "По названию",
            sortNameDesc: "По названию (по убыванию)",
            sortDate: "По Дате изменения",
            sortDateDesc: "По Дате изменения (по убыванию)",
            sortSize: "По Размеру",
            sortSizeDesc: "По Размеру (по убыванию)",
        },
        links: {
            title: "Доступ по ссылке",
            link: "Ссылка",
            copy: "Скопировать в буфер обмена",
            copied: "Ссылка скопирована в буфер обмена!",
            toLink: "Перейти",
        },
        errors: {
            getFolderData: "Не удалось отобразить содержимое!",
            getFile: "Не удалось загрузить файл",
            getFolderLink: "Не удалось получить ссылку на папку!",
        },
    },
    form: {
        isRequired: "Поле обязательно к заполнению",
        minLength: "Поле должно иметь не менее %s символов",
        maxLength: "Поле должно иметь не более %s символов",
        min: "Значение должно быть не меньше %s",
        max: "Значение должно быть не больше %s",
        cancelBtn: "Отмена",
        submitBtn: "Сохранить",
        isUrl: "Укажите корректную ссылку",
        isNumber: "Значение должно быть числом",
        isDate: "Укажите корректную дату",
        isGuid: "Укажите корректный GUID",
        isPhone: "Укажите корректный номер телефона",
        isEmail: "Укажите корректный Email",
        isSelectValue: "Выберите значение из списка",
        pattern: "Поле содержит недопустимые символы",
    },
    goodTable: {
        loading: "Загрузка...",
        rowsPerPage: "Записей на странице",
        noRecords: "Нет записей",
        filters: {
            value: "Значение",
            searchType: "Фильтр",
            asc: "По возрастанию",
            desc: "По убыванию",
        },
        toolbar: {
            search: "Введите текст для поиска",
        },
    },
    gradeStepSelect: {
        selectTitle: "Выберите шаг грейда",
        grade: "Грейд",
        errors: {
            getGradeSteps: "Не найдены шаги грейда",
        },
    },
    header: {
        profile: "Мой профиль",
        logout: "Выйти",
        search: "Поиск",
    },
    htmlEditor: {
        uploadFile: "Загрузить файл",
        video: {
            title: "Вставить ссылку на видео",
            link: "Ссылка на видео",
            cancel: "Отмена",
            ok: "Вставить",
            aspectRatio: "Соотношение сторон",
            aspectRatioVertical: "▯ Вертикальное",
            aspectRatioHorizontally: "▭ Горизонтальное",
            type: "Тип видео",
            typeYoutube: "YouTube",
            typeRutube: "Rutube",
            errors: {
                noVideoLink: "Укажите ссылку на видео",
                isNotLink: "Укажите корректную ссылку",
            },
        },
        iframe: {
            title: "Вставить iFrame",
            link: "Ссылка на iFrame",
            cancel: "Отмена",
            ok: "Вставить",
            aspectRatio: "Соотношение сторон",
            aspectRatioVertical: "▯ Вертикальное",
            aspectRatioHorizontally: "▭ Горизонтальное",
            errors: {
                isNotLink: "Укажите корректную ссылку",
                noVideoLink: "Укажите ссылку на iFrame",
            },
        },
        paste: {
            title: "Вставка текста",
            text: "Вы хотите вставить текст из Буфера обмена. Очистить формат?",
            cancel: "Нет, оставить как есть",
            ok: "Да, очистить",
        },
        errors: {
            fileExtFormat: "Данный тип файлов не поддерживается",
            fileUpload: "Ошибка при загрузке файла на сервер",
        },
        loading: "Подождите, редактор грузится",
    },
    image: {
        viewer: {
            errors: {
                load: "Не удалось загрузить изображение",
            },
            actions: {
                download: "Скачать",
                rotateRight: "Повернуть изображение  по часовой стрелке",
                rotateLeft: "Повернуть изображение против часовой стрелки",
                reset: "Сброс",
                close: "Закрыть",
            },
        },
        cropper: {
            actions: {
                rotateRight: "Повернуть изображение  по часовой стрелке",
                rotateLeft: "Повернуть изображение против часовой стрелки",
                reset: "Сброс",
                save: "Сохранить",
                close: "Закрыть",
            },
            selector: {
                tooltip: "Обрезать изображение",
                none: "Не обрезать",
                vertical: "Вертикально  (3 : 4)",
                horizontal: "Горизонтально (4 : 3)",
                square: "Квадрат (1 : 1)",
            },
        },
    },
    inputAutocomplete: {
        noOptionsText: "Ничего не найдено",
        insertSearchText: "Введите текст для поиска",
        selectAll: "Все",
    },
    inputClient: {
        title: "Поиск гостя",
        add: {
            title: "Новый гость",
            fields: {
                name: "Имя гостя",
                car: "Машина",
                category: "Категория",
                phone: "Номер телефона",
                email: "E-mail",
                telegram: "Telegram",
            },
            errors: {
                noContacts: "Укажите хотя бы один контакт",
                save: "Не удалось создать пользователя",
            },
        },
        search: {
            title: "Гости",
            searchText: "Текст для поиска",
            placeholder: "Введите номер телефона или имя гостя",
            onRecords: "По вашему запросу ничего не найдено",
            addAction: "Добавить нового гостя",
            fields: {
                name: "Имя",
                contact: "Контакт",
                createdDate: "Создан",
                category: "Категория",
                id: "ID",
            },
        },
    },
    inputCron: {
        everyMinute: "Каждую минуту",
        everyHour: "Каждый час",
        everyDay: "Каждый день",
        everyWeekDay: "Каждый день недели",
        everyWeek: "Каждую неделю",
        everyMonth: "Каждый месяц",
        everyYear: "Каждый год",
        weekDays: "Дни недели",
        monthDays: "Дни месяца",
        months: "Месяцы",
        hours: "Часы",
        minutes: "Минуты",
        prefixMinutes: "в минуты",
        prefixHours: "в часы",
        prefixMonthDays: "в дни месяца",
        prefixMonths: "в месяцы",
        prefixWeekDays: "в дни недели",
        errors: {
            invalidRange: "Неверный диапазон",
            invalidStepFormat: "Неверный формат шага",
            invalidNumber: "Неверное число",
        },
        input: {
            validation: {
                required: "Обязательно для заполнения",
                atLeastOneRequired: "Необходимо выбрать хотя бы один элемент",
            },
        },
        nextAt: "Следующий",
        repeat: "Повторять",
        setSchedule: "Установить расписание",
    },
    inputImage: {
        addTitle: "Загрузить",
        deleteTitle: "Удалить изображение?",
        deleteText: "Вы уверены, что хотите удалить изображение?",
        simplePastePlaceholder: "Вставить из буфера обмена",
        insertImage: "Буфер обмена не содержит изображения",
    },
    inputTimeDuration: {
        days: "Дней",
        hours: "Часов",
        minutes: "Минут",
        seconds: "Секунд",
    },
    inputVideo: {
        addTitle: "Загрузить",
        deteleTitle: "Удалить видео?",
        deteleText: "Вы уверены, что хотите удалить видео?",
    },
    inputFile: {
        placeholder: "Файл не выбран",
    },
    inputLesson: {
        selectTitle: "Выберите урок из списка",
        multiplePlaceholder: "Выберите уроки",
    },
    inputLessonCatalog: {
        multiplePlaceholder: "Выберите каталоги уроков",
        title: "Каталог уроков",
        toSelect: "Выбрать",
    },
    learning: {
        lesson: {
            toPrevStep: "Предыдущий шаг",
            toNextStep: "Продолжить",
            toFinish: "Завершить",
            toWaitingConfirm: "Запросить подтверждение",
            answers: {
                text: "Введите текст ответа",
                rightOrder: "Установите варианты в правильном порядке",
                test: "Выберите вариант ответа",
                multiTest: "Выберите варианты ответа",
            },
            errors: {
                toNextStep: "Не удалось отправить данные!",
            },
            finished: {
                title: 'Поздравляем. Вы успешно прошли урок "%s".',
            },
        },
        lessonStepError: {
            title: "Допущена ошибка",
            text: "Максимальное количество - %p#errors#. После этого урок будет провален!",
            correctAnswers: "Правильный ответ:",
            status: {
                completed: {
                    title: "Поздравляем!",
                    text: "Вы успешно прошли урок.",
                },
                expired: {
                    title: "Вы не успели",
                    text: "К сожалению вы не успели вовремя",
                },
                failed: {
                    title: "Урок провален",
                    textEmployee: "Слишком много ошибок. Обратитесь к менеджеру.",
                    text: "Слишком много ошибок. Обратитесь к сотруднику академии.",
                },
                terminated: {
                    title: "Урок отменен",
                    text: "Прохождение урока было отменено.",
                },
                waitingConfirm: {
                    title: "Урок ожидает подтверждения",
                    text: "Ожидайте, пока академия подтвердит прохождение урока.",
                    textEmployee: "Ожидайте, пока менеджер подтвердит прохождение урока.",
                },
            },
        },
        lessonTimer: {
            tooltip: "Осталось времени",
        },
        lessonErrors: {
            title: "Ошибки: %s из %s",
            titleNoMaxErrors: "Ошибок: %s",
        },
        gradeTreeViewer: {
            gradeTree: "Дерево грейдов",
            userNoGradeTrees: "Нет доступных деревьев грейдов. Обратитесь в Академию",
            annulatedStatus: "Аннулирован",
            new: "Новый",
        },
        gradeWarnings: {
            title: 'Грейд "%s" будет аннулирован %s',
        },
        gradeUserHistory: {
            noRecords: "Нет записей",
            fields: {
                comment: "Комментарий",
                type: "Действие",
                createdDate: "Дата",
                id: "ID",
            },
        },
        gradeStepList: {
            title: 'Грейд "%s". Пройдено %s из %s',
            adminStepActions: {
                toPass: "Выполнить",
                toRemove: "Провалить",
                toReset: "Аннулировать",
                toConfirm: "Подтвердить",
                toAssign: "Назначить",
            },
            adminGradeActions: {
                toPass: "Назначить грейд",
                toRemove: "Удалить грейд",
                toHistory: "История получения",
            },
        },
        errorFeedbackAdd: {
            title: "Обнаружена ошибка",
            titleTooltip: "отправить ошибку",
            text: "Введите текст, в чем заключается ошибка",
            save: "Отправить",
            selectedTextTitle: "Выделенный текст",
            success: {
                add: "Спасибо за ваше сообщение. Скоро мы все исправим.",
            },
            errors: {
                add: "Не удалось отправить сообщение об ошибке!",
            },
        },
    },
    loader: {
        defaultText: "Идет загрузка сайта. Подождите...",
    },
    mediaViewer: {
        tooltip: {
            toPrev: "Предыдущий",
            toNext: "Следующий",
        },
    },
    page: {
        notifications: {
            single: {
                confirm: {
                    title: "Перейти к опросу",
                    body: "Вы действительно хотите перейти к опросу?",
                },
                title: "Внимание опрос!",
                text: `Вам необходимо до %s пройти опрос "%s"`,
                action: "Пройти опрос",
            },
            multiple: {
                confirm: {
                    title: "Перейти к опросам",
                    body: "Вы действительно хотите перейти к опросам?",
                },
                title: "Доступно %s опросов!",
                text: "Ближайший истекает %s",
            },
            phoneCheck: {
                title: "Подтверждение номера телефона",
                text: "Вам необходимо подтвердить ваш номер телефона",
            },
        },
    },
    permissionChecker: {
        prompt: "Ожидание разрешения",
        denied: "%s запрещен. Пожалуйста, дайте разрешение на использование для данного сайта",
        camera: {
            noCameraAccessible: "Нет доступа к камере. Подключите камеру или попробуйте в другом браузере.",
            unknown: "Неизвестная ошибка",
        },
    },
    rRule: {
        result: "Следующие %s событий",
        variant: {
            monthlyDay: "По числам",
            monthlySetpos: "По дням недели",
            YEARLYDAY: "Каждый год (по числам)",
            YEARLYSETPOS: "Каждый год (по дням недели)",
            MONTHLY: "Каждый месяц",
            MONTHLYDAY: "Каждый месяц (по числам)",
            MONTHLYSETPOS: "Каждый месяц (по дням недели)",
            WEEKLY: "Каждую неделю",
            DAILY: "Каждый день",
            DAILYHOURS: "Каждый день (по часам)",
            HOURLY: "Каждый час",
        },
        interval: {
            YEARLYDAY: "год",
            YEARLYSETPOS: "год",
            MONTHLYDAY: "месяц",
            MONTHLYSETPOS: "месяц",
            WEEKLY: "неделю",
            DAILY: "день",
            HOURLY: "час",
        },
        fields: {
            startDate: "Дата начала",
            endDate: "Дата окончания",
            freq: "Повторять",
            interval: "Каждый",
            weekdays: "День",
            isOn: "Повторять событие",
            variant: "Вариант",
        },
        freq: {
            YEARLY: "Каждый%s год",
            MONTHLY: "Каждый%s месяц",
            WEEKLY: "Каждую%s неделю",
            DAILY: "Каждый%s день",
            HOURLY: "Каждый%s час",
        },
        days: {
            byhour: "Часы",
        },
        weekdays: {
            byweekdays: "День",
            0: "Понедельник",
            1: "Вторник",
            2: "Среда",
            3: "Четверг",
            4: "Пятница",
            5: "Суббота",
            6: "Воскресенье",
        },
        setpos: {
            bysetpos: "",
            t1: "Первый",
            t2: "Второй",
            t3: "Третий",
            t4: "Четвертый",
            lastDay: "Последний",
        },
        monthly: {
            day: "По числам",
            setpos: "По дням недели",
            lastDayMonthDay: "Последний день месяца",
            lastDaySetpos: "Последний",
            bymonthday: "Число",
            bymonth: "Месяц",
            1: "Январь",
            2: "Февраль",
            3: "Март",
            4: "Апрель",
            5: "Май",
            6: "Июнь",
            7: "Июль",
            8: "Август",
            9: "Сентябрь",
            10: "Октябрь",
            11: "Ноябрь",
            12: "Декабрь",
        },
        yearly: {
            on: "В",
            bymonth: "Месяц",
            bysetpos: "День",
            lastDayMonthDay: "Последний день месяца",
            lastDaySetpos: "Последний",
        },
    },
    signalR: {
        newMessage: "У вас новое оповещение",
    },
    table: {
        loading: "Загрузка...",
        rowsPerPage: "Записей на странице",
    },
    tableLogs: {
        title: "История изменений",
        noRecords: "Нет записей",
        action: "Действие",
        actions: {
            insert: "Добавление",
            update: "Редактирование",
            delete: "Удаление",
            softDelete: "Снятие с публикации",
            softRecover: "Восстановление",
        },
    },
    treeViewer: {
        clearValue: "Удалить значение",
    },
    inputTag: {
        addLabel: "Укажите значение",
        addSelect: 'Добавить "%s"',
        addSelectWithCategories: 'Добавить в категорию "%s" тег "%s"',
        noTagsText: "Нет тегов",
    },
    tradePointFormatFlagSelect: {
        title: "Форматы кофеен",
    },
    videoViewer: {
        errors: {
            load: "Не удалось загрузить видео",
        },
        actions: {
            download: "Скачать файл",
            close: "Закрыть",
        },
    },
    voiceReader: {
        accessDenied:
            "Доступ к микрофону запрещен. Чтобы использовать функцию распознавания голоса - разрешите доступ к камере для данного сайта",
        tooltip: "Голосовой набор. Работает пока удерживаете кнопку нажатой",
    },
    wazzup24: {
        title: "Чат с пользователем %s",
        tooltip: "Открыть час с пользователем в WhatsApp",
        errors: {
            getIframeUrl: "Не удалось открыть час с пользователем",
        },
    },
    uploader: {
        imageUploader: {
            add: "Загрузить изображение",
            rotateLeft: "Повернуть влево",
            rotateRight: "Повернуть вправо",
            toCancel: "Отмена",
            toSave: "Сохранить",
        },
        fileUploader: {
            refreshUpload: "Повторить",
            successUpload: "Готово",
            title: "Загрузка файла",
            filesCount: "Загружено %s из %s",
            errors: {
                title: "Ошибка при загрузке",
                fileSize: "Размер файла не должен превышать %s.! Вы пытаетесь загрузить файл размером %s.",
                maxFiles: "За раз можно загружать не более %s файлов, а вы пытаетесь загрузить %s файлов",
            },
        },
    },
    userSelect: {
        multiplePlaceholder: "Выберите пользователей",
        list: {
            title: "Добавить пользователей",
            roles: "По Должности",
            tradePoints: "По Кофейне",
            cities: "По городу",
            allList: "По ФИО",
            addBtn: "Добавить",
            fields: {
                fullName: "ФИО",
                role: "Должность",
                tradePoints: "Кофейня",
                phone: "Телефон",
                imageId: "Фото",
            },
        },
    },
};
export default ruComponents;
