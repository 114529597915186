const ruTables = {
    equipmentRegister: {
        title: "Реестр оборудования и инвентаря",
        downloadSuccess: "Реестр оборудования и инвентаря",
        fields: {
            name: "Наименование",
            description: "Характеристика",
            image: "Изображение",
            format: "Формат",
            equipmentType: "Тип",
        },
    },
    equipmentType: {
        title: "Категории оборудования",
        fields: {
            title: "Название",
            parentId: "Родитель",
        },
    },
    operationCard: {
        title: "КСО",
        fields: {},
    },
    orderFeedback: {
        title: "Отзывы из мобильного приложения",
        fields: {
            createdDate: "Время отзыва",
            orderCreateDate: "Время заказа",
            mark: "Оценка",
            orderNumber: "Номер заказа",
            tradePointId: "Кофейня",
        },
    },
    journalEquipments: {
        title: "Журнал оборудования",
        fields: {
            name: "Название",
            userRoleFlag: "Кто заполняет",
            equipmentTypeId: "Тип оборудования",
            secondsDuration: "Повторять 1 раз в",
            notificationSecondsDuration: "Напомнить за (до окончания)",
            notificationMessage: "Текст уведомления",
        },
    },
    journalUser: {
        title: "Журнал сотрудников",
        fields: {
            name: "Название",
            userRoleFlag: "Кто заполняет",
            tradePointUserRoleFlag: "Кого заполнять",
            secondsDuration: "Повторять 1 раз в",
            notificationSecondsDuration: "Напомнить за (до окончания)",
            notificationMessage: "Текст уведомления",
            isAnotherUsers: "Не сотрудники кофейни",
            isAnotherUsersHelp: "Можно добавить других пользователей",
        },
    },
    journalUserTradePoint: {
        title: "Кофейни",
        addTitle: "Добавить кофейни",
        editTitle: "Изменить настройки",
        fields: {
            tradePoint: "Кофейня",
            startDate: "Дата начала",
        },
        remove: {
            title: "Удалить кофейни",
            text: "Вы действительно хотите удалить кофейни?",
            okText: "Да, удалить",
        },
    },
};
export default ruTables;
