const ruPlural = {
    unreadNews: ["непрочитанная новость", "непрочитанные новости", "непрочитанных новостей"],
    grades: ["грейд", "грейда", "грейдов"],
    employees: ["сотрудник", "сотрудника", "сотрудников"],
    offences: ["нарушение", "нарушения", "нарушений"],
    rubs: ["рубль", "рубля", "рублей"],
    receipts: ["чек", "чека", "чеков"],
    scores: ["балл", "балла", "баллов"],
    feedback: ["отзыв", "отзыва", "отзывов"],
    clientCards: ["карточка", "карточки", "карточек"],
    steps: ["шаг", "шага", "шагов"],
    checkList: ["чек-лист", "чек-листа", "чек-листов"],
    complaints: ["обращение", "обращения", "обращений"],
    errors: ["ошибка", "ошибки", "ошибок"],
    templates: ["шаблон", "шаблона", "шаблонов"],
    goods: ["товар", "товара", "товаров"],
    questions: ["вопрос", "вопроса", "вопросов"],
    examinations: ["аттестация", "аттестации", "аттестаций"],
    lessons: ["урок", "урока", "уроков"],
};

export default ruPlural;
